<template>
    <HistoricoAcesso :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    import HistoricoAcesso from '../../components/consulta/HistoricoAcesso.vue';

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { HistoricoAcesso }
    }

</script>