<template>
    <div class="col-12">
        <div class="card">
            <h5>Histórico de Acesso</h5>			

            <DataTable :value="database" 
					:paginator="true" 
					class="p-datatable-gridlines" 
					:rows="10" 
					dataKey="id" 
					:rowHover="true" 
					v-model:filters="filters1" 
					filterDisplay="menu" 
					:loading="loading" 
					:filters="filters1" 
					responsiveLayout="stack"
					:globalFilterFields="['data','hora','ip','web']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>

				<Column field="data" header="Data acesso" :sortable="true" />
                <Column field="hora" header="Hora acesso" :sortable="true" style="text-align: center" />
                <Column field="ip" header="IP" :sortable="true" style="text-align: center" />
                <Column field="web" header="Canal" :sortable="true" style="text-align: left" />
			</DataTable>
            
        </div>
    </div>

</template>

<script>
    import {FilterMatchMode, FilterOperator} from 'primevue/api';
    import Formatacao from '../../utilities/Formatacao';
    import HistoricoAcessoService from "../../service/HistoricoAcessoService";

    export default {
        name: 'HistoricoAcesso', 

		props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data(){
            return {
                database: [],
				filters1: null,
				loading: true,            
            }
        },
        created(){
            this.initFilters1();
            this.getHistorico();
        },
        methods: {
            initFilters1() {
				this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
            getHistorico() {
                HistoricoAcessoService.getHistorico(this.$props.matricula).then((response) => {
                    this.database = response.data.content.map(h => {
						h.hora = this.formatDateCustom(h.data, 'HH:mm:ss');
						h.data = this.formatDate(h.data);
						h.web = h.web ? 'Web' : 'Mobile';
						return h;
					});
					
					this.loading = false;
				});
			},
			clearFilter1() {
				this.initFilters1();
			},            
			formatDate(data) {
            	return Formatacao.formatDate(data);
			},
			formatDateCustom(data, format) {
				return Formatacao.formatDateCustom(data, format);
			}
        }       
    }
    
</script>